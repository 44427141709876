<template>
  <v-app>
    <v-main>
      <v-container fill-height class="pa-0">
        <router-view/>
      </v-container>
    </v-main>
    <v-footer>
      <cookie-law
        :buttonText="$t('cookie-button')"
        theme="royal">
        <div slot="message">
          <span v-html="$t('cookie-law')" /> <a :href="cookieLink" target="_blank">{{ $t('cookie-more') }}</a>
        </div>
      </cookie-law>
    </v-footer>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',

  components: {
    CookieLaw
  },

  data() {
    return {
      cookieLink: this.$i18n.locale === 'en'
        ? 'https://gdpr.eu/cookies/'
        : 'https://gdpr.eu/cookies/'
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/common.scss';
</style>
