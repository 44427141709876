import Vue from 'vue'

const postRecipe = function(parameters, callback, errorCallback) {
  Vue.axios.post('/api/', parameters
  ).then(function(response) {
    callback(response.data)
  }).catch(function(e) {
    errorCallback(e)
  })
}

export default {
  postRecipe
}
