<template>
  <v-container bg fluid text-center class="pa-0">
    <div v-html="htmlContent" />
  </v-container>
</template>

<script>

export default {
  name: 'Help',
  data() {
    return {
      htmlContent: '',
      basePath: process.env.BASE_URL
    }
  },
  mounted() {
    this.loadHtml()
  },
  methods: {
    loadHtml() {
      const help = this.$i18n.locale === 'en' ? 'help.html' : 'help_japanese_ver.html'
      fetch(`${this.basePath}${help}`).then(res => {
        res.text().then(html => {
          this.htmlContent = html
        })
      })
    }
  }
}
</script>
