import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import api from '@/api'
import { createProvider } from './vue-apollo'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.prototype.api = api
Vue.api = api

new Vue({
  router,
  vuetify,
  apolloProvider: createProvider(),
  i18n,
  render: h => h(App)
}).$mount('#app')
