<template>
  <v-card width="100%">
    <v-card-title class="multiherbals">
      {{ $t('multi-herbal.title', { compoundName: compoundName }) }}
    </v-card-title>
    <v-card-subtitle class="multiherbals">
      Total: {{ medicines.length}}
    </v-card-subtitle>
    <v-data-table
      :headers="headers"
      :items="medicines"
      multi-sort
      :loading="loading"
      hide-default-footer
      disable-pagination
      class="sticky-half-table"
    >
      <template #[`item.knapsackId`]="{ item }">
        <a :href="`${item.knapsackUrl}`" target="_blank">
          {{ item.knapsackId }}
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'MultiHerbalMedicines',
  components: {},
  apollo: {
    medicines: {
      query: gql`query ($name: String, $mode: String) {
        multiHerbalMedicines(name: $name, mode: $mode) {
          name
          knapsackId
          knapsackUrl
        }
      }`,
      variables() {
        return {
          name: this.compoundName,
          mode: this.mode
        }
      },
      update: data => data.multiHerbalMedicines,
      result({ data, loading, networkStatus }) {
        this.loading = loading
      }
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('multi-herbal.name'),
          value: 'name',
          width: '70%'
        },
        {
          text: this.$t('multi-herbal.knapsack'),
          value: 'knapsackId',
          width: '30%'
        }
      ],
      medicines: [],
      compoundName: this.$route.params.drug,
      loading: true,
      mode: localStorage.getItem('mode')
    }
  }
}
</script>
