import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import GbFlag from '@/components/flags/GbFlag.vue'
import JpFlag from '@/components/flags/JpFlag.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {/**/},
  icons: {
    values: {
      gbflag: { // name of the custom icon
        component: GbFlag // the custom component
      },
      jpflag: {
        component: JpFlag
      }
    }
  }
})
