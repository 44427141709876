<template>
  <v-col cols="12">
    <div class="switch-database-title">
      {{$t('recipe.database-switch-title')}}
    </div>
    <v-btn-toggle
      v-model="selectedDatabase"
      rounded
      mandatory
      color="primary"
      @change="switchDatabase"
    >
      <v-btn value="KEGG">KEGG</v-btn>
      <v-btn value="UMLS">UMLS</v-btn>
    </v-btn-toggle>
  </v-col>
</template>

<script>
export default {
  data() {
    return {
      selectedDatabase: localStorage.getItem('database', 'KEGG')
    }
  },
  name: 'DatabaseSwitch',
  props: {},
  methods: {
    switchDatabase: function() {
      this.$emit('switchDatabase', this.selectedDatabase)
    }
  },
  mounted() {
    this.$emit('switchDatabase', this.selectedDatabase)
  }
}
</script>

<style scoped>
.switch-database-title {
  padding: 4px;
  font-size: 1.12rem;
  font-weight: 500;
}
</style>
