<template>
  <v-layout align-start flex-column>
    <v-card width="100%">
      <v-card-title class="drugs fixed-card-title">
        {{ $t('input-drugs.title') }}
      </v-card-title>
      <v-card-subtitle class="drugs fixed-card-subtitle">
        Total: {{ drugs.length }}
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="drugs"
        multi-sort
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="sticky-table"
      >
        <template #[`item.name`]="{ item }">
          <a :href="drugTarget(item)">{{ item.name }}</a>
        </template>
        <template #[`item.keggId`]="{ item }">
          <a
            v-if="item.keggId !== '-'"
            :href="`${item.keggUrl}`"
            target="_blank"
          >
            {{ item.keggId }}
          </a>
        </template>
      </v-data-table>
    </v-card>

    <v-spacer />

    <back-button />
  </v-layout>
</template>

<script>
import gql from 'graphql-tag'
import BackButton from '@/components/BackButton'

export default {
  name: 'InputCrudeDrugs',
  components: { BackButton },
  apollo: {
    drugs: {
      query: gql`query ($mode: String) {
        inputCrudeDrugs(mode: $mode) {
          name
          keggId
          keggUrl
        }
      }`,
      variables() {
        return {
          mode: this.mode
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.inputCrudeDrugs,
      result({ data, loading, networkStatus }) {
        this.loading = loading
      }
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('input-drugs.name'), value: 'name' },
        { text: this.$t('input-drugs.kegg'), value: 'keggId' }
      ],
      drugs: [],
      loading: true,
      mode: localStorage.getItem('mode')
    }
  },
  methods: {
    drugTarget(item) {
      const route = this.$router.resolve({
        name: 'DrugInfo',
        params: { drug: item.name }
      })
      return route.href
    }
  }
}
</script>
