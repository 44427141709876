<template>
  <v-layout align-start flex-column>
    <multi-herbal-medicines
      compound-name="compoundName"
      class="half-sticky-table mb-10"
    />

    <drug-constituents
      class="half-sticky-table"
    />

    <v-spacer />

    <back-button />
  </v-layout>
</template>

<script>
import MultiHerbalMedicines from '@/components/MultiHerbalMedicines'
import DrugConstituents from '@/components/DrugConstituents'
import BackButton from '@/components/BackButton'

export default {
  name: 'DrugInfo',
  components: {
    MultiHerbalMedicines,
    DrugConstituents,
    BackButton
  }
}
</script>
