<template>
  <v-layout align-start flex-column>
    <v-card width="100%">
      <v-card-title class="proteins fixed-card-title">
        {{ $t('target-proteins.title') }} {{ $t('target-proteins.info') }}
      </v-card-title>
      <v-card-subtitle class="proteins fixed-card-subtitle">
        Total: {{ proteins.length}}
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="proteins"
        multi-sort
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="sticky-table"
      >
        <template #[`item.interactionScore`]="{ item }">
          {{ formatScore(item.interactionScore )}}
        </template>
        <template #[`item.ncbiId`]="{ item }">
          <a :href="`${item.ncbiUrl}`" target="_blank">{{ item.ncbiId }}</a>
        </template>
      </v-data-table>
    </v-card>

    <v-spacer />

    <back-button />
  </v-layout>
</template>

<script>
import gql from 'graphql-tag'
import BackButton from '@/components/BackButton'
import { roundScore } from '@/mixins/utils'

export default {
  name: 'TargetProteins',
  components: { BackButton },
  apollo: {
    proteins: {
      query: gql`query ($mode: String) {
        targetProteins(mode: $mode) {
          name
          description
          interactionScore
          ncbiId
          ncbiUrl
        }
      }`,
      variables() {
        return {
          mode: this.mode
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.targetProteins,
      result({ data, loading, networkStatus }) {
        this.loading = loading
      }
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('target-proteins.name'), value: 'name' },
        { text: this.$t('target-proteins.description'), value: 'description' },
        { text: this.$t('target-proteins.score'), value: 'interactionScore' },
        { text: this.$t('target-proteins.ncbi'), value: 'ncbiId' }
      ],
      proteins: [],
      loading: true,
      mode: localStorage.getItem('mode')
    }
  },
  methods: {
    formatScore(score) {
      return roundScore(score)
    }
  }
}
</script>
