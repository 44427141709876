<template>
  <v-expansion-panels
    v-model="homePanel"
    accordion
    flat
    class="pa-0 full-height-panel"
  >
    <v-expansion-panel>
      <v-expansion-panel-header v-if="homePanel != 0" hide-actions>
        <div
          class="v-btn v-btn--is-elevated v-btn--has-bg theme--light"
        >
          {{ $t('new-recipe') }}
          <v-icon>mdi-chevron-double-down</v-icon>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0 full-height-panel">
        <recipe-selection @cookingDone="showResults"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="homePanel == 1">
      <v-expansion-panel-content>
        <result-selection />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import gql from 'graphql-tag'
import RecipeSelection from '@/components/RecipeSelection'
import ResultSelection from '@/components/ResultSelection'

export default {
  name: 'Home',
  components: {
    RecipeSelection,
    ResultSelection
  },
  apollo: {
    crudeDrugs: {
      query: gql`query {
        allCrudeElements {
          name
        }
      }`,
      update: data => data.allCrudeElements
    },
    exampleDrugs: {
      query: gql`query {
        allKanpoElements{
          name
        }
      }`,
      update: data => data.allKanpoElements
    }
  },
  data() {
    return {
      inputedCrudeDrugs: [...Array(25)].map((_, i) => ({ name: '', dose: '' })),
      selectdExample: '',
      crudeDrugs: [],
      exampleDrugs: [],
      loading: false,
      homePanel: Number(sessionStorage.getItem('homePanel')) || 0
    }
  },
  mounted() {},
  methods: {
    showResults() {
      this.homePanel = 1
    }
  },
  watch: {
    homePanel: function() {
      sessionStorage.setItem('homePanel', this.homePanel)
    }
  }
}
</script>

<style scope>
.full-height-panel {
  height: 100%;
}
.v-expansion-panel-content__wrap:first-child {
  padding: 0;
}
</style>
