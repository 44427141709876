<template>
  <v-btn block class="mt-5" max-height="36px" :to="resultsTarget()">
    <v-icon>mdi-chevron-double-left</v-icon>
    {{ $t('back-button') }}
  </v-btn>
</template>

<script>
export default {
  name: 'BackButton',
  methods: {
    resultsTarget() {
      const route = this.$router.resolve({
        name: 'home'
      })
      return route.href
    }
  }
}
</script>
