<template>
  <v-layout align-start flex-column>
    <v-card width="100%">
      <v-card-title class="diseases fixed-card-title">
        {{ $t('applicable-diseases.title') }}
        <span v-if="mode == 'example'" class="show-whitespace">
          {{ $t('applicable-diseases.info') }}
        </span>
      </v-card-title>
      <v-card-subtitle class="diseases fixed-card-subtitle">
        Total: {{ diseases.length}}
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="diseases"
        multi-sort
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="sticky-table"
      >
        <template #[`item.count`]="{ item }">
          <a :href="pathwayTarget(item)">{{ item.count }}</a>
        </template>
        <template #[`item.diseaseScore`]="{ item }">
          {{ formatScore(item.diseaseScore )}}
        </template>
        <template #[`item.targetId`]="{ item }">
          <a :href="`${item.targetUrl}`" target="_blank">{{ item.targetId }}</a>
        </template>
      </v-data-table>
    </v-card>

    <v-spacer />

    <back-button />
  </v-layout>
</template>

<script>
import gql from 'graphql-tag'
import BackButton from '@/components/BackButton'
import { roundScore } from '@/mixins/utils'

export default {
  name: 'ApplicableDiseases',
  components: { BackButton },
  apollo: {
    diseases: {
      query: gql`query ($mode: String) {
        applicableDiseases(mode: $mode) {
          name
          count
          diseaseScore
          targetId
          targetUrl
        }
      }`,
      variables() {
        return {
          mode: this.mode
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.applicableDiseases,
      result({ data, loading, networkStatus }) {
        this.loading = loading
      }
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('applicable-diseases.name'), value: 'name' },
        { text: this.$t('applicable-diseases.count'), value: 'count' },
        { text: this.$t('applicable-diseases.score'), value: 'diseaseScore' }
      ],
      diseases: [],
      loading: true,
      mode: localStorage.getItem('mode'),
      database: localStorage.getItem('database')
    }
  },
  methods: {
    pathwayTarget(item) {
      const route = this.$router.resolve({
        name: 'Pathway',
        params: { pathway: item.targetId }
      })
      return route.href
    },
    formatScore(score) {
      return roundScore(score)
    }
  },
  mounted() {
    if (this.database === 'KEGG') {
      this.headers.push({ text: 'KEGG disease pathway ID', value: 'targetId' }
      )
    } else {
      this.headers.push({ text: 'UMLS ID', value: 'targetId' }
      )
    }
  }
}
</script>

<style scoped>
.tableee {
  height: calc(100vh - 56px);
}
</style>
