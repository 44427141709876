import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import ApplicableDiseases from '@/views/ApplicableDiseases'
import TargetProteins from '@/views/TargetProteins'
import ConstituentCompounds from '@/views/ConstituentCompounds'
import InputCrudeDrugs from '@/views/InputCrudeDrugs'
import Pathway from '@/views/Pathway'
import DrugInfo from '@/views/DrugInfo'
import Help from '@/views/Help'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/applicable_diseases',
      name: 'ApplicableDiseases',
      component: ApplicableDiseases
    },
    {
      path: '/target_proteins',
      name: 'TargetProteins',
      component: TargetProteins
    },
    {
      path: '/constituent_compounds',
      name: 'ConstituentCompounds',
      component: ConstituentCompounds
    },
    {
      path: '/input_crude_drugs',
      name: 'InputCrudeDrugs',
      component: InputCrudeDrugs
    },
    {
      path: '/pathway/:pathway',
      name: 'Pathway',
      component: Pathway
    },
    {
      path: '/drug/:drug',
      name: 'DrugInfo',
      component: DrugInfo
    },
    {
      path: '/help',
      name: 'Help',
      component: Help
    }
  ]
})
