<template>
  <v-container bg fluid fill-height text-center class="pa-0">
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-flex>
      <v-toolbar
        color="transparent"
        flat
        dense
      >
        <v-spacer />
        <v-btn
          v-if="method === 'original'"
          small
          depressed
          @click="method = 'example'"
          class="mr-4"
          :width="$i18n.locale == 'en' ? '180px' : '110px'"
        >
          {{ $t("recipe.kanpo-mode") }}
        </v-btn>
        <v-btn
          v-else-if="method === 'example'"
          small
          depressed
          @click="method = 'original'"
          class="mr-4"
          width="110px"
        >
          {{ $t("recipe.custom-mode") }}
        </v-btn>

        <v-btn
          v-if="$i18n.locale == 'en'"
          small
          depressed
          @click="setLocale('jp')"
          class="text-none"
          width="110px"
        >
          <v-icon left>$vuetify.icons.jpflag</v-icon>
          日本語
        </v-btn>

        <v-btn
          v-else
          small
          depressed
          @click="setLocale('en')"
          class="text-none test"
          width="110px"
        >
          <v-icon left>$vuetify.icons.gbflag</v-icon>
          English
        </v-btn>

        <v-btn
          small
          depressed
          class="ml-4"
          width="86px"
        >
          <router-link :to="helpPageUrl()" class="no-style-link">
            <v-icon left color="black">mdi-help-circle-outline</v-icon>
            {{ $t("help") }}
          </router-link>
        </v-btn>
      </v-toolbar>
      <div class="title mb-5">
        <img
          src="~@/assets/chef_icon.png"
          alt="Icon"
          width="100"
          class="title-img"
        >
        <span style="font-weight:bold;font-size: 48px">MedicalHerbChef</span>
      </div>
      <v-row>
        <template v-if="method === 'original'">
          <v-col cols="12">
            <div class="mode-title">
              {{ $t("recipe.formula-mode") }}
            </div>
            <v-form ref="recipeForm">
              <v-card
                elevation="16"
                max-width="450"
                max-height="300"
                class="mx-auto scroll"
              >
                <v-card-title class="justify-center inner-title">
                  {{ $t("recipe.explanation") }}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      {{ $t("recipe.crude") }}
                    </v-col>
                    <v-col cols="4">
                      {{ $t("recipe.dosage") }}
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in inputDrugs"
                        :key="index"
                        dense
                      >
                        <v-col cols="9" class="py-0">
                          <v-autocomplete
                            v-model="item.name"
                            :items="selectableDrugs(item.name)"
                            item-value="name"
                            :item-text="item => formatDrugName(item.name)"
                            :placeholder="$t('recipe.drug_placeholder')"
                            dense
                            :disabled="isDisabled(index)"
                          />
                        </v-col>
                        <v-col cols="3" class="py-0">
                          <v-text-field
                            v-model.number="item.dose"
                            type="number"
                            dense
                            placeholder="(g)"
                            :rules="dosageRules(index)"
                            validation-on-blur
                            :disabled="isDisabled(index)"
                          />
                        </v-col>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-form>
          </v-col>
          <DatabaseSwitch
            @switchDatabase="switchDatabase"
          />

          <v-col cols="12">
            <!-- Considering the size difference of the image, it is necessary to create a space -->
            <button
              class="calc-btn mt-4"
              style="margin-bottom:50px"
              @click="onClickCalculation"
            >
              <span class="calc-txt">{{ $t('calculate') }}</span>
              <img
                src="~@/assets/calculation.png"
                width="200"
                height="150"
                alt="Calculation"
              />
            </button>
          </v-col>
        </template>
        <template v-if="method === 'example'">
          <v-col cols="12">
            <div class="mode-title">
              {{ $t("recipe.search-mode") }}
            </div>
            <v-card
              elevation="16"
              max-width="450"
              height="300"
              class="mx-auto scroll"
            >
              <v-card-title class="justify-center inner-title">
                {{ $t("recipe.search") }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="searchString"
                  :placeholder="$t('recipe.search-placeholder')"
                  dense
                  hide-details
                  class="mt-0 pb-1 search-field"
                />
                <v-data-table
                  v-model="selectedExample"
                  :items="filteredExamples"
                  item-key="name"
                  :headers="exampleHeaders"
                  hide-default-footer
                  disable-pagination
                  dense
                  show-select
                  single-select
                >
                  <template #[`item.name`]="{ item }">
                    <td
                      class="text-start row-pointer"

                    >
                      <span v-html="item.value" />
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <DatabaseSwitch
            @switchDatabase="switchDatabase"
          />
          <v-col cols="12">

            <button class="analyse-btn mt-4" @click="onClickAnalyse">
              <span class="analyse-txt">{{ $t('analyse') }}</span>
              <img
                src="~@/assets/analyse.png"
                width="200"
                height="200"
                alt="Analyze"
              />
            </button>
          </v-col>
        </template>
      </v-row>

      <div class="title mt-5 font-weight-bold">
        {{ $t('recipe.herbchef') }}
      </div>
    </v-flex>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import Swal from 'sweetalert2'
import { genFilteredText } from '@/mixins/utils'
import DatabaseSwitch from '@/components/DatabaseSwitch'

export default {
  name: 'RecipeSelection',
  components: { DatabaseSwitch },
  apollo: {
    crudeDrugs: {
      query: gql`query {
        allCrudeElements {
          name
        }
      }`,
      update: data => data.allCrudeElements
    },
    exampleDrugs: {
      query: gql`query {
        allKanpoElements{
          name
        }
      }`,
      update: data => data.allKanpoElements
    },
    crudeDrugsJp: {
      query: gql`query {
        allCrudeElementsJp {
          name
        }
      }`,
      update: data => data.allCrudeElementsJp,
      // Disable the query at first because English is the default
      skip() {
        return this.$i18n.locale === 'en'
      }
    },
    exampleDrugsJp: {
      query: gql`query {
        allKanpoElementsJp {
          name
        }
      }`,
      update: data => data.allKanpoElementsJp,
      skip() {
        return this.$i18n.locale === 'en'
      }
    }
  },
  data() {
    return {
      method: localStorage.getItem('mode') || 'original',
      inputDrugs: [...Array(25)].map(() => ({ name: '', dose: '' })),
      selectedExample: [],
      crudeDrugs: [],
      exampleDrugs: [],
      // For caching, use two different sets of variables
      crudeDrugsJp: [],
      exampleDrugsJp: [],
      exampleHeaders: [
        { value: 'data-table-select', width: '34px' },
        { text: this.$i18n.t('recipe.medicine-name'), value: 'name' }
      ],
      searchString: '',
      loading: false,
      panel: 0
    }
  },
  computed: {
    filteredExamples: function() {
      const examples =
        this.$i18n.locale === 'en' ? this.exampleDrugs : this.exampleDrugsJp
      if (this.searchString === '') {
        return examples.map(e => { return { name: e.name, value: e.name } })
      } else {
        const filteredItems = examples.filter(e =>
          e.name.includes(this.searchString.toLocaleLowerCase())
        )
        const hightlightedItems = filteredItems.map(e => {
          return {
            'name': e.name,
            'value': genFilteredText(e.name, this.searchString)
          }
        })
        return hightlightedItems
      }
    }
  },
  mounted() {},
  methods: {
    helpPageUrl() {
      const route = this.$router.resolve({
        name: 'Help'
      })
      return route.href
    },
    switchDatabase(selectedDatabase) {
      this.selectedDatabase = selectedDatabase
      localStorage.setItem('database', selectedDatabase)
    },
    onClickCalculation() {
      const nbIngredients = this.inputDrugs.filter(d => d.name !== '').length
      if (nbIngredients < 1) {
        Swal.fire({
          icon: 'error',
          title: this.$t('recipe.not-enough_error-title'),
          text: this.$t('recipe.not-enough_error-text'),
          confirmButtonText: this.$t('recipe.not-enough_error-button')
        })
      } else if (this.$refs.recipeForm.validate()) {
        const self = this
        self.loading = true
        const postData = this.inputDrugs.filter(d => d.name !== '')
        this.api.postRecipe(
          { 'compounds': postData, 'lang': this.$i18n.locale, 'database': this.selectedDatabase },
          function(response) {
            localStorage.setItem('mode', 'original')
            self.loading = false
            self.$emit('cookingDone')
          },
          function() {
            self.loading = false
            Swal.fire({
              icon: 'error',
              title: this.$t('error-title'),
              text: this.$t('error-text')
            })
          })
      }
    },
    onClickAnalyse() {
      if (this.selectedExample.length === 0) {
        Swal.fire({
          icon: 'error',
          title: this.$t('recipe.no-example-selected-title'),
          text: this.$t('recipe.no-example-selected-text')
        })
      } else {
        this.loading = true
        const self = this
        this.api.postRecipe({
          'example': this.selectedExample[0].name,
          'lang': this.$i18n.locale,
          'database': this.selectedDatabase
        },
        function(response) {
          localStorage.setItem('mode', 'example')
          self.loading = false
          self.$emit('cookingDone')
        },
        function() {
          self.loading = false
          Swal.fire({
            icon: 'error',
            title: self.$t('error-title'),
            text: self.$t('error-text')
          })
        })
      }
    },
    formatDrugName(name) {
      return name.replaceAll('_', ' ')
    },
    dosageRules(index) {
      if (this.inputDrugs[index].name !== '') {
        return [
        // v => !!v || 'Dosage is required',
          v => (v > 0 && v <= 10) || this.$t('recipe.dosage-error')
        ]
      } else {
        return []
      }
    },
    isDisabled(index) {
      if (index < 1) {
        return false
      } else {
        for (let i = 0; i < index; ++i) {
          if (this.inputDrugs[i].name === '') {
            return true
          }
        }
        return false
      }
    },
    setLocale(locale) {
      this.$i18n.locale = locale
      localStorage.setItem('locale', locale)
      this.inputDrugs = [...Array(25)].map(() => ({ name: '', dose: '' }))
      this.selectedExample = []
    },
    selectableDrugs(selected) {
      const drugs =
        this.$i18n.locale === 'en' ? this.crudeDrugs : this.crudeDrugsJp
      return drugs.filter(d => {
        return (d.name === selected) ||
          (this.inputDrugs.find(i => i.name === d.name) === undefined)
      })
    }
  }
}
</script>

<style scoped>
.bg {
  position: relative;
}
.bg:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("~@/assets/home.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.calc-btn {
  position: relative;
}
.calc-txt {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.analyse-btn {
  position: relative;
  transform: translate(-10%, 0%);
}
.analyse-txt {
  position: absolute;
  top: 45%;
  left: 60%;
  transform: translate(-50%, -50%);
}
.scroll {
  overflow-y: scroll;
}
.test {
  text-align: left !important;
}
.inner-title {
  padding: 4px;
  font-size: 1.12rem;
}
.no-style-link {
  text-decoration: none;
  color: inherit;
}
.right-border {
  border-right: 3px solid black;
}
.mode-title {
  font-size: 1.50rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  margin-bottom: 4px;
}
</style>

<style>
/* DOM content created with v-html are not affected by scoped styles */
.v-list-item__mask {
  color: rgba(0, 0, 0, 0.38);
  background: #eeeeee;
}
</style>
