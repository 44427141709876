<template>
  <v-layout align-start flex-column>
    <v-card width="100%">
      <v-card-title class="compounds fixed-card-title">
        {{ $t('constituent-compounds.title') }}
      </v-card-title>
      <v-card-subtitle class="compounds fixed-card-subtitle">
        Total: {{ compounds.length}}
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="compounds"
        multi-sort
        :loading="loading"
        hide-default-footer
        disable-pagination
        class="sticky-table"
      >
        <template #[`item.knapsackId`]="{ item }">
          <a :href="`${item.knapsackUrl}`" target="_blank">
            {{ item.knapsackId }}
          </a>
        </template>
      </v-data-table>
    </v-card>

    <v-spacer />

    <back-button />
  </v-layout>
</template>

<script>
import gql from 'graphql-tag'
import BackButton from '@/components/BackButton'

export default {
  name: 'ConstituentCompounds',
  components: { BackButton },
  apollo: {
    compounds: {
      query: gql`query ($mode: String) {
        constituentCompounds(mode: $mode) {
          name
          knapsackId
          knapsackUrl
        }
      }`,
      variables() {
        return {
          mode: this.mode
        }
      },
      fetchPolicy: 'no-cache',
      update: data => data.constituentCompounds,
      result({ data, loading, networkStatus }) {
        this.loading = loading
      }
    }
  },
  data() {
    return {
      headers: [
        { text: this.$t('constituent-compounds.name'), value: 'name' },
        { text: this.$t('constituent-compounds.knapsack'), value: 'knapsackId' }
      ],
      compounds: [],
      loading: true,
      mode: localStorage.getItem('mode')
    }
  }
}
</script>
