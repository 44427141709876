const tagsToReplace = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;'
}

export const escapeHTML = (str) => {
  return str.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag)
}

export const genFilteredText = (text, searchInput) => {
  text = text || ''
  if (!searchInput) return escapeHTML(text)

  const { start, middle, end } = getMaskedCharacters(text, searchInput)

  return `${escapeHTML(start)}${genHighlight(middle)}${escapeHTML(end)}`
}

export const genHighlight = (text) => {
  return `<span class="v-list-item__mask">${escapeHTML(text)}</span>`
}

export const getMaskedCharacters = (text, searchInput) => {
  searchInput = searchInput.toLocaleLowerCase()
  const index = text.toLocaleLowerCase().indexOf(searchInput)

  if (index < 0) return { start: text, middle: '', end: '' }

  const start = text.slice(0, index)
  const middle = text.slice(index, index + searchInput.length)
  const end = text.slice(index + searchInput.length)
  return { start, middle, end }
}

export const roundScore = (val) => {
  if (Number.isInteger(val)) {
    return val
  } else {
    const decimals = 3
    return Number(Math.round(val + 'e' + decimals) + 'e-' + decimals).toFixed(3)
  }
}
