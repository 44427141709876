<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="6">
        <v-card
          outlined
          color="transparent"
          @click="nextPage('ApplicableDiseases')"
        >
          <v-img
            src="@/assets/applicable_diseases.png"
            alt="Applicable diseases"
          >
            <v-card-text class="card-title text-left-top">
              {{ $t('results.applicable-diseases')}}
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          color="transparent"
          @click="nextPage('TargetProteins')"
        >
          <v-img
            src="@/assets/target_proteins.png"
            alt="Target proteins"
          >
            <v-card-text class="card-title text-right-top">
              {{ $t('results.target-proteins')}}
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          color="transparent"
          @click="nextPage('ConstituentCompounds')"
        >
          <v-img
            src="@/assets/constituent_compounds.png"
            alt="Constituent compounds"
          >
            <v-card-text class="card-title text-left-bottom">
              {{ $t('results.constituent-compounds')}}
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          color="transparent"
          @click="nextPage('InputCrudeDrugs')"
        >
          <v-img
            src="@/assets/input_crude_drugs.png"
            alt="Input crude drugs"
          >
            <v-card-text class="card-title text-right-bottom">
              {{ $t('results.input-drugs')}}
            </v-card-text>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from '@/router'

export default {
  methods: {
    nextPage(pageName) {
      router.push({ name: pageName })
    }
  }
}
</script>

<style scoped>
.card-title {
  color: white;
  font-size: 30px;
  line-height: 1.2em;
}
.text-left-top {
  text-align: left;
}
.text-left-bottom {
  position: absolute;
  bottom: 0;
}
.text-right-top {
  text-align: right;
}
.text-right-bottom {
  text-align: right;
  position: absolute;
  bottom: 0;
}
</style>
